<template>
  <stemble-card
    color="rgba(11,87,155)"
    image
    data-test="assignment"
    :data-test-id="assignment.id"
    padding="3"
  >
    <template #heading>
      <s-stack gap="1" style="white-space: nowrap; overflow: hidden">
        <s-stack>
          <p
            class="font-weight-medium text-h4"
            style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
          >
            {{ assignment.name }}
          </p>
          <p class="text-subtitle-2">
            {{ assignment.course.name }}
          </p>
        </s-stack>

        <p class="white--text text-body-2">
          <v-tooltip v-if="showExtensionIcon" top open-delay="500">
            <template v-slot:activator="{on, attrs}">
              <v-icon v-bind="attrs" v-on="on">
                {{ $i('assignment.extensions') }}
              </v-icon>
            </template>
            {{ $t('extensionToolTip') }}
          </v-tooltip>
          Due: {{ formattedDueDate }}
        </p>
      </s-stack>
    </template>

    <s-stack gap="1">
      <s-stack direction="horizontal" align="space-between" valign="center">
        <div>
          <v-btn
            :ripple="false"
            :aria-label="$t('goTo') + courseLikeAssignment.assignment.name"
            class="no-background-hover"
            elevation="1"
            color="secondary"
            rounded
            width="100%"
            small
            :to="assignmentRouteTo"
            data-test="assignment-overview-btn"
          >
            {{ $t('assignment') }}
          </v-btn>
        </div>

        <div style="text-align: end">
          <span
            v-if="!gradeIsLocked"
            style="
              font-weight: bold;
              font-size: 12px;
              display: flex;
              align-items: center;
              gap: 0.5rem;
            "
          >
            {{ $t('grade') }}:
            <span style="font-weight: bold; font-size: 24px" :class="gradeColor">
              <assignment-grade :grade="grade" :course="assignment.course" />
            </span>
          </span>
          <span v-else style="font-weight: bold; font-size: 12px">
            {{ $t('grade') }}:
            <v-tooltip bottom open-delay="250">
              <template #activator="{attrs, on}">
                <span style="font-weight: bold; font-size: 24px" v-bind="attrs" v-on="on">
                  <v-icon>{{ $i('assignment.lockedGrade') }}</v-icon>
                </span>
              </template>
              {{ $t('grade-is-locked') }}
            </v-tooltip>
          </span>
        </div>
      </s-stack>
    </s-stack>
  </stemble-card>
</template>

<script>
import Vue from 'vue';
import AssignmentGrade from '@/grades/models/AssignmentGrade';
import AssignmentGradeComponent from '@/assignments/components/AssignmentGrade';
import moment from 'moment';
import {ASSIGNMENT_OVERVIEW} from '@/router/route-names';
import {AssignmentPolicyAnalyzer} from '@/assignment-policies/utils/assignment-policy-analyzer';
import {getGradeColorClass} from '@/grades/utils/grade-color';
import StembleCard from '@/common/components/StembleCard.vue';
import SStack from '@/common/components/SStack.vue';
import {LoadingFlag} from '@/loading/types/LoadingFlags';

export default Vue.extend({
  name: 'StudentAssignmentCard',
  components: {StembleCard, SStack, AssignmentGrade: AssignmentGradeComponent},
  props: {
    assignment: undefined,
  },
  data: function () {
    return {
      opacity: 0.3,
      isToggled: false,
    };
  },
  computed: {
    showExtensionIcon() {
      return this.assignment.extendedDueDate;
    },
    isGradeLoading() {
      return this.$loadingFlags.isLoading(LoadingFlag.LoadingAssignmentGrade);
    },
    gradeIsLocked() {
      return this.grade === null && !this.policyAnalyzer.isGradeVisible();
    },
    gradeColor() {
      return getGradeColorClass(this.grade);
    },
    grade() {
      return AssignmentGrade.queryByUserAssignment(this.$auth.user.id, this.assignment.id)
        .with('gradeAdjustments')
        .first();
    },
    courseLikeAssignment() {
      return this.assignment.courseLikeAssignments[0];
    },
    policyAnalyzer() {
      return new AssignmentPolicyAnalyzer(this.courseLikeAssignment);
    },
    formattedDueDate() {
      return this.assignment.extendedDueDate
        ? moment(this.assignment.extendedDueDate).format('llll')
        : moment(this.courseLikeAssignment.dueDate).format('llll');
    },
    formattedStartDate() {
      return moment(this.courseLikeAssignment.startDate).format('llll');
    },
    assignmentRouteTo() {
      return {
        name: ASSIGNMENT_OVERVIEW,
        params: {
          courseId: this.assignment.courseId,
          assignmentId: this.assignment.id,
        },
      };
    },
  },
});
</script>
